import React, {useRef, useState} from 'react'
import AsyncSelect  from 'react-select/async'

import {CompanyService} from "@/common/api/CompanyService";
import useDebounce from "@/common/hooks/useDebounce";
import { AddressData } from "@/common/models/address"
import {getPointIfPresent} from "@/common/utils/utils";
import { SelectOption } from '@/common/models/util'
import {components, OptionProps} from "react-select";

export const customSelectStyles = {
    input: (provided) => ({
        ...provided,
        padding: "0.2rem 0.5rem",
        fontSize: "1rem"
    }),
    control: (provided) => ({
        ...provided,
        borderRadius: "0.5rem"
    }),
};

const AddressAsyncSelect = ({ prevAddress, onSelect = () => {}, onInputChange = () => {} }: AddressAsyncSelectProps) => {
    const selectRef = useRef(null)
    const [searchQuery, setSearchQuery] = useState<string>(prevAddress?.building ?? "");
    const [selectedOption, setSelectedOption] = useState<SelectOption>({
        value: {
            building: prevAddress?.building,
            cityId: prevAddress?.city?.id,
            cityName: prevAddress?.city?.name,
            point: getPointIfPresent(prevAddress?.point?.lat, prevAddress?.point?.lon)
        },
        label: prevAddress?.street ?
            `${prevAddress?.building}`
            : "",
    })

    const buildSelectOption = (item) => ({
        value: item,
        label: `${item?.street}, ${item?.building}${item?.apartment ? `, ${item?.apartment}` : ""}`,
    });

    const fetchSuggestions = async (query: string, callback) => {
        if (query?.length < 1 && !prevAddress?.street) return [];
        try {
            const response = await CompanyService.suggestAddresses({
                cityId: prevAddress.city.id,
                street: prevAddress.street,
                building: query
            });
            if(response?.items?.length === 0) {
                callback([{
                    value: 'error',
                    label: `Адрес ${prevAddress?.street}, ${query} не найден`,
                    isHint: true,
                    isDisabled: true
                }])
            } else {
                const fetchedOptions = response?.items || [];

                callback([...fetchedOptions.map(buildSelectOption), { value: "hint", label: 'Пожалуйста, выберите вариант из списка', isHint: true, isDisabled: true }]);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const debouncedFetchSuggestions = useDebounce(fetchSuggestions, 1000)

    const handleSelectInputChange = (value: string, {action}) => {
        if(action === 'input-change') {
            if(selectedOption?.value?.building !== value && selectedOption?.label) {
                setSelectedOption(prev => ({
                    ...prev,
                    value: {
                        ...prev.value,
                        point: null
                    },
                }))
                onSelect({
                    cityId: prevAddress?.city?.id,
                    street: prevAddress?.street,
                    building: searchQuery,
                    point: null,
                })
            }
            onInputChange(value)
            setSearchQuery(value);

        }
        if(action === "input-blur" || action === "menu-close") {
            onInputChange(searchQuery)
        }
    };

    const handleOptionSelect = (option) => {
        onSelect(option?.value);
        setSelectedOption({
            ...option,
            label: option?.value?.building
        })
        setSearchQuery(option?.value?.building)
        onInputChange(option?.value?.building)
        selectRef.current.blur()
        selectRef.current.focus()
        selectRef.current.blur()
    };

    const handleBlurSelect = () => {
        if(!selectedOption) {
            onSelect({
                cityId: prevAddress?.city?.id,
                street: prevAddress?.street,
                building: searchQuery,
                point: selectedOption?.value?.point,
            })
        }

    };

    return (
        <div>
            <AsyncSelect
                ref={selectRef}
                placeholder={null}
                value={selectedOption}
                styles={customSelectStyles}
                components={{Option: CustomOption}}
                loadOptions={debouncedFetchSuggestions}
                onChange={handleOptionSelect}
                inputValue={searchQuery}
                onInputChange={handleSelectInputChange}
                onBlur={handleBlurSelect}
                blurInputOnSelect={true}
                loadingMessage={() => <span className={"text-primary"}>Загрузка...</span>}
                noOptionsMessage={() => "Введите дом"}
            />
        </div>

    );
}

interface AddressAsyncSelectProps {
    prevAddress: AddressData,
    onSelect?: (address: AddressData) => void,
    onInputChange?: (inputValue: string) => void,
}

const CustomOption: React.FC<CustomOptionProps> = (props) => {
    return (
        <components.Option {...props}>
            {props.data.isHint ? (
                props.data.value === "error" ? (
                    <div className={"text-danger"}>{props.data.label}</div>
                    ) : (
                    <div className={"text-success"}>{props.data.label}</div>
                )
            ) : (
                props.children
            )}
        </components.Option>
    );
};

interface CustomOptionProps extends OptionProps<any, any> {

    data: {
        label: string;
        value: any;
        [key: string]: any;
    };
}

export default AddressAsyncSelect