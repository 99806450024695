export enum Formats {
    DATE_TIMESTAMP = "yyyy-MM-dd'T'HH:mm:ss",
    DATE_DMY_TIME = "dd/MM/yyyy - HH:mm",
    DATE_DMY = "dd-MM-yyyy",
    DATE_DMY_DOT = "dd.MM.yyyy",
	DATE_YMD = "yyyy-MM-dd",
}

export const possibleDateFormats = [
    'yyyy-MM-dd',
    'dd-MM-yyyy',
    'MM/dd/yyyy',
    'yyyy/MM/dd',
    'dd/MM/yyyy',
    'yyyyMMdd',
    'ddMMyyyy'
]
