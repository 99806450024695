import React, { useState } from 'react'
import { ArrowRepeat } from 'react-bootstrap-icons'
import { observer } from 'mobx-react-lite'
import {Alert, Tab, Tabs} from 'react-bootstrap'
import { useQuery } from 'react-query'
import {useTranslation} from "react-i18next";

import OrderList from './OrderList'
import OrderService from '@/telegram/api/OrderService'
import { OrderApiPageResponse } from "@/common/models/api/v0/order.dto"
import {Statuses} from "@/common/constants/statuses";

const OrderSection = observer(() => {
    const { t } = useTranslation()
    const [filterQuery, setFilterQuery] = useState<string>(Statuses.courierAppStatusLists['pickup']);
    const { data, isFetching, refetch: refetchOrders, error } = useQuery<OrderApiPageResponse>(['courier-orders', filterQuery],
        () => OrderService.getAll({ deliveryStatuses: filterQuery }))
    const handleTabChange = (tab: string) => {
        setFilterQuery(Statuses.courierAppStatusLists[tab])
    }
    return (
        <div>
            <div className="mt-3">
                <Tabs
                    className="my-2"
                    id="courier-orders-tab"
                    activeKey={filterQuery}
                    onSelect={handleTabChange}
                    fill
                >
                    {
                        Object.keys(Statuses.courierAppStatusLists).map(list => (
                            <Tab eventKey={list} tabClassName={filterQuery === Statuses.courierAppStatusLists[list] ?
                                "text-primary" : "bg-dark bg-opacity-10 text-black"} title={t(`courierTabs.${list}`)}/>
                        ))
                    }
                </Tabs>
                <div className="d-flex align-items-center">
                    <span className="heading-3 my-3">Назначены мне</span>
                    <span
                        className="ms-auto d-flex align-items-center border border-rounded-1 bg-light p-2"
                        onClick={() => refetchOrders()}
                    >
                        <ArrowRepeat size="25" />
                    </span>
                </div>
                {error && (
                    <Alert variant="danger">
                        <div>
                            <span className="heading-font">Oops. Something went wrong</span>
                        </div>
                        <div>
                            <span>{(error as any).response?.status}</span>
                            <span className="mx-1">{JSON.stringify((error as any).response?.body?.errorMessage)}</span>
                        </div>
                    </Alert>
                )}
            </div>
            {data && (
                <OrderList
                    data={data}
                    isLoading={isFetching}
                />
            )}
        </div>
    )
})

export default OrderSection