import React, { FC, useRef, useState } from 'react'
import { Camera } from 'react-camera-pro'
import { UploadFile, Image } from 'antd'
import { useTranslation } from 'react-i18next'

const CameraCapture: FC<CameraCaptureProps> = ({ onShot, onClose }) => {
    const { t } = useTranslation()

    const camera = useRef(null)
    const [previewFiles, setPreviewFiles] = useState<string[]>([])

    const handleCameraShot = (fileBase64: string) => {
        setPreviewFiles([...previewFiles, fileBase64])
        onShot({ uid: '', name: `camera-${fileBase64.substring(20, 25)}`, url: fileBase64 })
    }

    const handleCameraClose = () => {
        setPreviewFiles([])
        onClose()
    }

    return (
        <div className="camera-container">
            <div>
                <Camera
                    facingMode="environment" 
                    ref={camera} 
                    errorMessages={{}} 
                />
            </div>
            <div className="camera-preview-bar">
                {previewFiles.map((item, index) => (
                    <div className="px-2">
                        <Image
                            className="border"
                            key={index}
                            width={48}
                            src={item}
                        />
                    </div>
                ))}
            </div>
            <div className="camera-control-bar">
                <button 
                    className="camera-shoot-btn" 
                    onClick={() => handleCameraShot(camera.current.takePhoto())}
                />
                <span
                    className="camera-close-btn"
                    onClick={handleCameraClose}
                >
                    {t('telegram.pages.orderAction.buttons.closeCameraBtn')}
                </span>
            </div>
        </div>
    )
}

interface CameraCaptureProps {
    onShot: (file: UploadFile) => void,
    onClose: () => void,
}

export default CameraCapture