import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import {
  LeftOutlined,
  RightOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  UndoOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { Card, Flex, Image, Space, Spin, Typography } from 'antd';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';

import './index.scss';
import { Courier } from '@/common/models/courier';
import OrderService from '@/common/api/OrderService';
import { OrderAttachmentsResponse } from '@/common/models/api/v0/order.dto';

const { Title } = Typography;

const OrderAttachmentViewPage = () => {
  const { t } = useTranslation()
  let { orderId } = useParams();
  const location = useLocation();
  const courier: Courier = location?.state?.courier;
  const {
    data: ordersWithAttachments,
    isFetching: isOrdersFetching,
  } = useQuery<OrderAttachmentsResponse>(['order-attachment'], () => OrderService.getOrderAttachments(orderId));

  return (
    <Flex className={'attach'} vertical={true}>
      {
        (isOrdersFetching) && (
          <Spin indicator={
            <LoadingOutlined
              className={'attach-spinner'}
              spin />
          } />
        )
      }

      {
        (!isOrdersFetching && ordersWithAttachments?.length < 1) && (
          <Title className={'attach-title'} level={2} style={{ marginTop: '1.25rem' }}>В этом заказе нет вложений</Title>
        )
      }

      {
        (ordersWithAttachments?.length > 0 && !isOrdersFetching) && (
          <>
            <Title
              className={'attach-title'}
              level={2}
            >
              {t('attachmentPage.header', {
                firstName: courier?.firstName,
                lastName: courier?.lastName,
              })}
            </Title>
            <Image.PreviewGroup
              preview={{
                toolbarRender: (
                  _,
                  {
                    transform: { scale },
                    actions: {
                      onActive,
                      onFlipY,
                      onFlipX,
                      onRotateLeft,
                      onRotateRight,
                      onZoomOut,
                      onZoomIn,
                      onReset,
                    },
                  },
                ) => (
                  <Space size={12} className='toolbar-wrapper'>
                    <LeftOutlined onClick={() => onActive?.(-1)} />
                    <RightOutlined onClick={() => onActive?.(1)} />
                    <SwapOutlined rotate={90} onClick={onFlipY} />
                    <SwapOutlined onClick={onFlipX} />
                    <RotateLeftOutlined onClick={onRotateLeft} />
                    <RotateRightOutlined onClick={onRotateRight} />
                    <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                    <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                    <UndoOutlined onClick={onReset} />
                  </Space>
                ),
              }}
            >
              <Flex
                wrap={'wrap'}
              >
                {ordersWithAttachments?.map((image) => (
                  <Card
                    hoverable
                    className='attach-card'
                  >
                    <Image key={image?.id} src={image?.file} height={200} />
                  </Card>
                ))}
              </Flex>
            </Image.PreviewGroup>
          </>
        )
      }
    </Flex>
  );
};

export default OrderAttachmentViewPage;