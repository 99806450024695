import React, { CSSProperties, FC } from 'react';
import { Spinner } from 'react-bootstrap';
import DefaultButton from '@/components/buttons/DefaultButton';
import { useTranslation } from 'react-i18next';

interface ChangeStatusConfirmProps {
  status,
  clearStatus?: () => {},
  handleChangeStatus: () => {},
  isLoading?: boolean,
  className?: string,
  styles?: CSSProperties
}

const ChangeStatusConfirm: FC<ChangeStatusConfirmProps> = ({status, clearStatus = () => {}, handleChangeStatus, isLoading = false, className = '', styles = {}}) => {
  const { t } = useTranslation()
  return (
    <div className={`position-absolute d-flex justify-content-center ${className}`} style={styles}>
      <div style={{border: "2px solid #b7b7b7", backgroundColor: "rgb(249,249,249)"}} className={"d-flex flex-column align-items-center p-2 border-opacity-10 border-rounded-1 bg-opacity-10"}>
        <p className="heading-5 my-0 ">{t('status.confirm')}<br/><span className={"text-primary"}>{status?.name}</span>?</p>
        <div className="d-flex justify-content-around my-1">
          {isLoading ? (<Spinner/>) : (
            <>
              <DefaultButton variant={"primary"} className={"mx-2"} onClick={handleChangeStatus}>Да</DefaultButton>
              <DefaultButton className={"text-light"} variant={"danger"} onClick={clearStatus}>Нет</DefaultButton>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChangeStatusConfirm;