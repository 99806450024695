import {useEffect, useState} from 'react'
import {Badge, Button, Modal, Table} from "react-bootstrap"
import {FilterCircleFill, SortDownAlt, SortUpAlt} from "react-bootstrap-icons"
import {observer} from "mobx-react-lite"
import {useTranslation} from 'react-i18next'
import {t} from 'i18next'

import '../../../styles/common.css'
import {OrderItem} from "@/common/models/order"
import useSort from "@/common/hooks/useSort"
import {appendString, buildDeliveryPartyString, convertToTimeZone, getDeliveryPartyCity} from "@/common/utils/utils"
import OrderItemDetailsView from './OrderItemDetailsView'
import OrderActionDropdown from "@/components/controls/order-action/OrderActionDropdown"
import OrderService from '@/common/api/OrderService';
import useAuth from "@/common/hooks/useAuth";
import useOrderSearch from "@/common/hooks/useOrderSearch";
import {Formats} from "@/common/constants/dateFormat";
import Checkbox from "@/components/controls/checkbox/Checkbox";
import {SetURLSearchParams} from "react-router-dom";

const OrdersContentTable = observer<OrdersListProps>(({data, expanded = true, onAnyChange = () => {}, orderDataById, orderItemId, setSearchParams}) => {
	const {t} = useTranslation()

	const {persistedSortOptions, persistSortOptions} = useOrderSearch()
	const {
		sortedData,
		onSortChange,
		getSortDirection
	} = useSort<OrderItem>(data, persistedSortOptions, persistSortOptions)
	const {user} = useAuth()

	const {selectedOrders, setSelectedOrders} = useOrderSearch()
	const [isAllItemsSelected, setIsAllItemsSelected] = useState(countSelectedOrdersOnPage(selectedOrders, sortedData) === sortedData.length)

	const unselectAllOrdersList = (selectedOrders, sortedData) => {
		let filteredOrders = selectedOrders
		for (let i = 0; i < sortedData.length; i++) {
			filteredOrders = filteredOrders.filter(filterOrder => filterOrder?.id !== sortedData[i]?.id)
		}
		return filteredOrders
	}
	const selectAllOrdersList = (selectedOrders, sortedData) => {
		let filteredOrders = selectedOrders
		for (let i = 0; i < sortedData.length; i++) {
			if (!filteredOrders.find(filterOrder => filterOrder?.id === sortedData[i]?.id)) {
				filteredOrders = [...filteredOrders, sortedData[i]]
			}
		}
		return filteredOrders
	}

	const handleAllItemsSelect = () => {
		if (isAllItemsSelected === true) {
			setSelectedOrders(unselectAllOrdersList(selectedOrders, sortedData))
			setIsAllItemsSelected(false)
		}
		if (isAllItemsSelected === false) {
			setSelectedOrders(selectAllOrdersList(selectedOrders, sortedData))
			setIsAllItemsSelected(true)
		}
	}

	useEffect(() => {
		setIsAllItemsSelected(countSelectedOrdersOnPage(selectedOrders, sortedData) === sortedData.length)
	}, [selectedOrders, sortedData])

	const handleShow = (orderItem) => {
		setSearchParams({id: orderItem.id});
	};

	const handleClose = () => {
		setSearchParams({});
	};

	return (
		<div style={{height: "100%"}}>
			<Table style={{height: "100%"}} bordered striped size="sm">
				<thead style={{position: 'sticky', top: '-5px', zIndex: "3", backgroundColor: "#63e2ec"}}>
				{expanded ? (
					<tr style={{position: 'sticky', top: '-5px'}}>
						<th style={{width: '50px'}}>
							<Checkbox
								className={"m-auto"}
								checked={isAllItemsSelected}
								indeterminate={!isAllItemsSelected && countSelectedOrdersOnPage(selectedOrders, sortedData) > 0}
								onChange={handleAllItemsSelect}
							/>
						</th>
						<th><p className={"d-inline-block mb-3"}>№</p></th>
						<th onClick={() => onSortChange('createDate')}>
							Создан
							<SortColumn
								key="createDate"
								direction={getSortDirection('createDate')}
							/>
						</th>
						<th>Номер накладной</th>
						<th>Отправитель</th>
						<th>Контакты отправителя</th>
						<th>Получатель</th>
						<th>Контакты получателя</th>
						<th onClick={() => onSortChange('courier', 'firstName')}>
							Курьер
							<SortColumn
								key="courier"
								direction={getSortDirection('courier')}
							/>
						</th>
						<th onClick={() => onSortChange('zone')}>
							Маршрут
							<SortColumn
								key="zone"
								direction={getSortDirection('zone')}
							/>
						</th>
						<th onClick={() => onSortChange('cell')}>
							Ячейка
							<SortColumn
								key="cell"
								direction={getSortDirection('cell')}
							/>
						</th>
						<th onClick={() => onSortChange('estimatedDeliveryDate')}>
							Предварительная дата доставки
							<SortColumn
								key="estimatedDeliveryDate"
								direction={getSortDirection('estimatedDeliveryDate')}
							/>
						</th>
						<th>Детали заказа</th>
						<th onClick={() => onSortChange('status')}>
							Статус
							<SortColumn
								key="status"
								direction={getSortDirection('status')}
							/>
						</th>
					</tr>
				) : (
					<tr>
						<th>№</th>
						<th onClick={() => onSortChange('createDate')}>
							Создан
							<SortColumn
								key="createDate"
								direction={getSortDirection('createDate')}
							/>
						</th>
						<th>Получатель (адрес, организация)</th>
						<th onClick={() => onSortChange('parcelType')}>
							Тип
							<SortColumn
								key="parcelType"
								direction={getSortDirection('parcelType')}
							/>
						</th>
						<th onClick={() => onSortChange('deliveryType')}>
							Доставка
							<SortColumn
								key="deliveryType"
								direction={getSortDirection('deliveryType')}
							/>
						</th>
						<th onClick={() => onSortChange('estimatedDeliveryDate')}>
							Примерная дата доставки
							<SortColumn
								key="estimatedDeliveryDate"
								direction={getSortDirection('estimatedDeliveryDate')}
							/>
						</th>
						<th onClick={() => onSortChange('status')}>
							Статус
							<SortColumn
								key="status"
								direction={getSortDirection('status')}
							/>
						</th>
					</tr>
				)}
				</thead>
				<tbody>
				{expanded ? (
					sortedData.map((order, index) => (
						<OrderStatefulRow
							key={index}
							order={order}
							onDetailsClick={() => handleShow(order)}
							onAnyChange={onAnyChange}
						/>
					))
				) : (
					sortedData.map((order, index) => (
						<tr key={index} onClick={() => handleShow(order)}>
							<td>{order.id}</td>
							<td>{order?.createDate && convertToTimeZone(order?.createDate, false, Formats.DATE_DMY_TIME)}</td>
							<td>{buildDeliveryPartyString(order?.deliveryReceiver)}</td>
							<td>{t(`parcelType.${order.parcelType}`)}</td>
							<td>{t(`deliveryType.${order.deliveryType}`)}</td>
							<td>{order.estimatedDeliveryDate && convertToTimeZone(order?.estimatedDeliveryDate, false, Formats.DATE_DMY_TIME)}</td>
							<td>
								{t(`orderStatus.${order.status}`, {
									from_city: getDeliveryPartyCity(order.deliverySender),
									to_city: getDeliveryPartyCity(order.deliveryReceiver),
								})}
							</td>
						</tr>
					))
				)}
				</tbody>
			</Table>
			<Modal show={!!orderItemId} onHide={handleClose} dialogClassName="wide-modal">
				<Modal.Header className="d-flex justify-content-between">
					<Modal.Title className="pl-5">Детали заказа {orderDataById?.id}</Modal.Title>
					<Button variant="light" onClick={handleClose}>
						X
					</Button>
				</Modal.Header>
				<Modal.Body>
					<OrderItemDetailsView onEdit={onAnyChange} orderItem={orderDataById} role={user.roles[0]}/>
				</Modal.Body>
			</Modal>
		</div>
	)
})

const SortColumn = ({direction}: SortColumnProps) => {

	const SortDirection = () => {
		if (!direction) {
			return
		}

		return direction === 'asc'
			? <SortUpAlt/>
			: <SortDownAlt/>
	}

	return (
		<div className="d-flex align-items-center cursor">
			<FilterCircleFill/>
			<span className="px-1"></span>
			<SortDirection/>
		</div>
	)
}

export function countSelectedOrdersOnPage(selectedOrders, sortedData) {
	let count = 0;
	for (let i = 0; i < sortedData?.length; i++) {
		const isSelected = sortedData[i]?.id === selectedOrders?.find(selectedOrder => selectedOrder?.id === sortedData[i]?.id)?.id
		if (isSelected) {
			count += 1
		}
	}
	return count
}


const OrderStatefulRow = ({
														order, onDetailsClick = () => {
	}, onAnyChange = () => {
	}
													}: OrderStatefulRowProps) => {
	const [orderStatus, setOrderStatus] = useState<string | null>(order.status)

	const [isActionLoading, setIsActionLoading] = useState<boolean>(false)
	const [actionError, setActionError] = useState()
	const [isActionSuccess, setIsActionSuccess] = useState<boolean>(false)

	const {selectedOrders, setSelectedOrders} = useOrderSearch()
	const [isSelected, setIsSelected] = useState(!!selectedOrders.find(iterableOrder => iterableOrder?.id === order?.id))
	const handleSelectOrder = (order) => {
		const orderAlreadySelected = !!selectedOrders.find(iterableOrder => iterableOrder?.id === order?.id)
		if (!orderAlreadySelected) {
			setSelectedOrders([...selectedOrders, order])
			setIsSelected(true)
		} else {
			setSelectedOrders(selectedOrders.filter(iterableOrder => iterableOrder?.id !== order?.id))
			setIsSelected(false)
		}
	}

	useEffect(() => {
		setIsSelected(!!selectedOrders.find(iterableOrder => iterableOrder?.id === order?.id))
	}, [selectedOrders, order?.id])

	useEffect(() => {
		setOrderStatus(order.status)
	}, [order])
	return (
		<tr>
			<td>
				<Checkbox
					key={isSelected}
					checked={isSelected}
					onChange={() => handleSelectOrder(order)}
				/>
			</td>
			<td>
				<span>{order.id}</span>
				<div className="position-relative">
					<Badge
						className="bg-light text-dark cursor"
						onClick={() => onDetailsClick()}
						pill
					>
						Детали
					</Badge>
					<OrderActionDropdown
						deliveryProcessType={order.deliveryProcessType}
						status={orderStatus}
						error={actionError}
						isLoading={isActionLoading}
						isSuccess={isActionSuccess}
						onClick={(event) => {
							setIsActionLoading(true)
							setActionError(null)
							OrderService.changeStatus(order.id, event)
								.then((newStatus) => {
									setOrderStatus(newStatus)
									setIsActionSuccess(true)
									onAnyChange()
								})
								.catch((e) => {
									setActionError(e)
									setIsActionSuccess(false)
								})
								.finally(() => setIsActionLoading(false))
						}}
					/>
				</div>
			</td>
			<td>{order?.createDate && convertToTimeZone(order?.createDate, false, Formats.DATE_DMY_TIME)}</td>
			<td>{order.orderNumber}</td>
			<td>{buildDeliveryPartyString(order?.deliverySender)}</td>
			<td>{appendString(order?.deliverySender?.contactName, order?.deliverySender?.phone)}</td>
			<td>{buildDeliveryPartyString(order?.deliveryReceiver)}</td>
			<td>{appendString(order?.deliveryReceiver?.contactName, order?.deliveryReceiver?.phone)}</td>
			<td>{appendString(order?.courier?.lastName, order?.courier?.firstName)}</td>
			<td>{order.route}</td>
			<td>{order.cell}</td>
			<td>{order?.estimatedDeliveryDate && convertToTimeZone(order?.estimatedDeliveryDate, false, Formats.DATE_DMY_TIME)}</td>
			<td>{order.comments}</td>
			<td>
				{t(`orderStatus.${order.status}`, {
					from_city: getDeliveryPartyCity(order.deliverySender),
					to_city: getDeliveryPartyCity(order.deliveryReceiver),
				})}
			</td>
		</tr>
	)
}

interface OrdersListProps {
	data: OrderItem[],
	expanded?: boolean,
	onAnyChange: () => void,
	orderDataById?: OrderItem,
	setSearchParams: SetURLSearchParams;
	orderItemId?: string;
}

interface SortColumnProps {
	key: string,
	direction: string,
}

interface OrderStatefulRowProps {
	order: OrderItem,
	onDetailsClick: () => void,
	onAnyChange: () => void,
}

export default OrdersContentTable
