import React, { FC, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { CameraFill, Paperclip } from 'react-bootstrap-icons'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { Button, GetProp, Image, Upload, UploadFile, UploadProps } from 'antd'
import { ResponseError } from 'superagent'

import { AttachmentResponse } from '@/common/models/api/v0/attachment.dto'
import OrderService from '@/telegram/api/OrderService'
import CameraCapture from './CameraCapture'
import useAlert from '@/common/hooks/useAlert'

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]

type UploadT = {
    error?: string,
    isUploading: boolean,
    files: UploadFile[],
}

type PreviewT = {
    isOpen: boolean,
    imageBase64: string,
}

const getBase64 = (file: FileType): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result as string)
        reader.onerror = (error) => reject(error)
    })

const toUploadFile = (attachments: AttachmentResponse[]): UploadFile[] => 
    attachments.map((a, index) => ({
        uid: `${index}`,
        name: `#${a.orderItemId}-${a.id}`,
        status: 'done',
        url: a.file,
        preview: a.file,
    }))

const OrderAttachmentList: FC<OrderAttachmentsProps> = ({ orderItemId }) => {
    const { data: attachments, refetch: refetchAttachments, isFetching: isFetchingAttachments } = useQuery<AttachmentResponse[]>(['order-attachments'], 
        () => OrderService.getAllFiles(orderItemId),
        { onError: (error) => handleApiError(error as ResponseError) })

    const { t } = useTranslation()
    const { sendError } = useAlert()

    const handleApiError = (error: ResponseError) =>
        sendError(error.response?.body?.errorMessage || t('api.messages.serviceDown'))

    const [upload, setUpload] = useState<UploadT>({
        files: [],
        isUploading: false,
    })
    const [preview, setPreview] = useState<PreviewT>({
        imageBase64: '',
        isOpen: false,
    })

    const [isCameraOpen, setIsCameraOpen] = useState(false)

    const setIsUploading = (isUploading: boolean) => 
        setUpload(prevState => ({ ...prevState, isUploading }))

    const setFiles = (files: UploadFile[]) =>
        setUpload(prevState => ({ ...prevState, files }))

    const setPreviewImage = (imageBase64: string) =>
        setPreview(prevState => ({ ...prevState, imageBase64 }))

    const setPreviewIsOpen = (isOpen: boolean) =>
        setPreview(prevState => ({ ...prevState, isOpen }))

    const handleUpload = () => {
        setIsUploading(true)
        const urls: string[] = upload.files.map(f => f.url)
        OrderService.saveAllFiles(orderItemId, urls)
            .then(() => {
                refetchAttachments()
                setFiles([])
            })
            .catch(e => handleApiError(e))
            .finally(() => setIsUploading(false))
    }

    const handlePreview: UploadProps['onPreview'] = async (file) => {
        if (!file.url?.includes('base64')) {
            file.url = await getBase64(file.originFileObj as FileType)
            setPreviewImage(file.url)
            setPreviewIsOpen(true)
        }
    }

    const handleRemove: UploadProps['onRemove'] = (file) => {
        const index = upload.files.indexOf(file)
        const newFileList = upload.files.slice()
        newFileList.splice(index, 1)
        setFiles(newFileList)
    }

    const beforeUpload: UploadProps['beforeUpload'] = async (file) => {
        const newFile = file as UploadFile
        newFile.url = await getBase64(file as FileType)
        setFiles([...upload.files, file])
        return false
    }

    const handleCameraShot = (cameraImage: UploadFile) => {
        setFiles([...upload.files, cameraImage])
    }

    const handleCameraClose = () => {
        setIsCameraOpen(false)
    }

    return isFetchingAttachments ? (
        <div className="d-flex justify-content-center border-dotted border-rounded-2 p-3">
            <Spinner />
        </div>
    ) : (
        <div>
            {isCameraOpen && (
                <CameraCapture
                    onShot={handleCameraShot}
                    onClose={handleCameraClose}
                />
            )}
            <div>
                <Button
                    icon={<CameraFill />} 
                    onClick={() => setIsCameraOpen(!isCameraOpen)}
                >
                    {t('telegram.pages.orderAction.buttons.openCameraBtn')}
                </Button>
            </div>
            <div className="py-2">
                <Upload
                    listType="picture"
                    accept='image/*'
                    onPreview={handlePreview}
                    onRemove={handleRemove}
                    beforeUpload={beforeUpload}
                    fileList={upload.files}
                >
                    <Button disabled={upload.isUploading} icon={<Paperclip />}>Загрузить</Button>
                </Upload>
                {upload.files.length > 0 && (
                    <div className="py-2">
                        <Button disabled={upload.isUploading} type="primary" onClick={handleUpload}>Отправить</Button>
                    </div>
                )} 
            </div>
            {attachments && attachments.length > 0 ? (
                <Upload
                    listType="picture"
                    onPreview={handlePreview}
                    fileList={toUploadFile(attachments)}
                >
                </Upload>
            ) : (
                <div className="d-flex justify-content-center border-dotted border-rounded-2 p-3">
                    <span className="heading-font text-muted">{t('telegram.pages.orderAction.noAttachments')}</span>
                </div>
            )}
            {preview.imageBase64 && (
                <Image
                    wrapperStyle={{ display: 'none' }}
                    preview={{
                        visible: preview.isOpen,
                        onVisibleChange: (visible) => setPreviewIsOpen(visible),
                        afterOpenChange: (visible) => !visible && setPreviewImage(''),
                    }}
                    src={preview.imageBase64}
                />
            )}
        </div>
    )
}

interface OrderAttachmentsProps {
    orderItemId: number,
}

export default OrderAttachmentList