import { User } from '@/common/models/user'
import React from 'react'
import {Roles} from "@/common/constants/roles";
import { BoxArrowRight } from 'react-bootstrap-icons';

const UserProfile = ({ showNav, user, onSignOut }: UserProfileProps) => {
    if (!user) {
        return
    }

    return (
        <div className="d-flex justify-content-around bg-light py-2 border-rounded-2">
            {(!showNav) && (
              <div className="user-card">
                  <span className='fw-medium text-muted'>{user.username}</span>
                  <div>
                      {user.roles.map((role, index) => (
                        <span key={index} className=" fw-medium">
                            {roleLabels[role]}
                        </span>
                      ))}
                  </div>
              </div>
            )}
            <div className="logout btn btn-outline-danger"
                 style={{position: 'relative', left: `${showNav ? '0' : '1.5vw'}`}}
                 onClick={onSignOut}
            >
                <BoxArrowRight
                  id="logout"
                  className="fas"
                  style={{position: 'relative', left: `${showNav ? '0' : '-0.4vw'}`}}
                />
            </div>
        </div>
    )
}

/**
 * TODO: Add i18n for managing text resources!!!
 */
const roleLabels = {
    [Roles.Manager]: 'Менеджер',
    [Roles.Client]: 'Клиент',
    [Roles.Admin]: 'Администратор',
}

interface UserProfileProps {
    showNav: boolean,
    user: User,
    onSignOut: () => void,
}

export default UserProfile